import classnames from 'classnames/bind'
import { FormProvider } from 'react-hook-form'
import { useTranslate } from 'react-polyglot'
import { SINGLE_ROUTES } from '~/lib/constants'

import BirthDate from '~/components/Account/Forms/BirthDate'
import Civility from '~/components/Account/Forms/Civility'
import Email from '~/components/Account/Forms/Email'
import FirstName from '~/components/Account/Forms/FirstName'
import LastName from '~/components/Account/Forms/LastName'
import Passwords from '~/components/Account/Forms/Passwords'
import LayoutUnLogged from '~/components/Account/UnLogged/Layout'
import Checkbox from '~/components/Form/Checkbox'
import InlineCta from '~/components/InlineCta'
import { PanelLogin } from '~/components/Panels'
import RichText from '~/components/RichText'
import SquaredCta, { ButtonThemes } from '~/components/SquaredCta'

import { usePanel } from '~/providers/PanelProvider'
import { useStyle } from '~/providers/StyleProvider'

import useShopifyEnableAccountMutation from '~/hooks/account/useShopifyEnableAccountMutation'
import useShopifyRegisterMutation from '~/hooks/account/useShopifyRegisterMutation'
import useFormWithMutation from '~/hooks/useFormWithMutation'
import useSingleRoutesRedirect from '~/hooks/useSingleRoutesRedirect'

import css from './styles.module.scss'
import { LayoutRegisterProps, RegisterProps } from './types'

const cx = classnames.bind(css)

function Register({ className, activate, panel }: RegisterProps) {
  const redirect = useSingleRoutesRedirect()
  const { add, data, removeCurrent } = usePanel()
  const { account } = data
  const t = useTranslate()

  const titleStyle = useStyle({ textPreset: 'text-13-18' })
  const ctaStyle = useStyle({ textPreset: 'cta-form-11' })
  const textStyle = useStyle({ textPreset: 'text-9-11', color: 'gray-47' })
  const newsletterStyle = useStyle({
    textPreset: 'text-12-14',
    color: 'gray-47',
  })

  const onSuccess = () => {
    removeCurrent()
    redirect(SINGLE_ROUTES.account)
  }

  const { mutate: registerMutation, isLoading: isRegistering } =
    useShopifyRegisterMutation(
      {
        onSuccess,
      },
      (...props) => form.setError(...props),
    )

  const { mutate: enableAccountMutation, isLoading: isEnabling } =
    useShopifyEnableAccountMutation({}, (...props) => form.setError(...props))

  const formData = activate
    ? {
        mutation: enableAccountMutation,
        key: 'enable_account',
      }
    : {
        mutation: registerMutation,
        key: 'register',
      }

  const { form, onSubmit } = useFormWithMutation(
    formData.mutation,
    null,
    formData.key,
  )

  return (
    <div className={cx(css.Register, className)}>
      <FormProvider {...form}>
        <form className={cx(css.form, { panel })} onSubmit={onSubmit}>
          <div className={cx(titleStyle, css.title)}>
            {account?.registerTitle && (
              <RichText render={account.registerTitle} />
            )}
          </div>
          <div className={css.center}>
            <Civility />
            <div className={css.name}>
              <FirstName />
              <LastName />
            </div>
            <Email />
            <Passwords />
            <BirthDate required={false} />
            <Checkbox
              className={cx(newsletterStyle, css.newsletter)}
              name="acceptsMarketing"
              label={t('form.newsletter')}
            />
            {account?.termsAndConditions && (
              <RichText
                className={cx(textStyle, css.termsAndConditions)}
                render={account.termsAndConditions}
              />
            )}
          </div>
          <div className={css.ctaWrapper}>
            <SquaredCta
              className={css.submit}
              withBackground
              withBorder
              fullWidth
              theme={ButtonThemes.Dark}
              isLoading={isRegistering || isEnabling}
              type="submit">
              {activate ? t('account.enable') : t('account.register')}
            </SquaredCta>
            {!activate && (
              <div className={cx(ctaStyle, css.connect)}>
                <span>{t('account.signed_up')}</span>
                <InlineCta
                  className={cx(ctaStyle, css.link)}
                  onClick={(e) => {
                    e.preventDefault()
                    if (panel) add({ component: <PanelLogin /> })
                    else redirect(SINGLE_ROUTES.login)
                  }}>
                  {t('account.connect')}
                </InlineCta>
              </div>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

export default Register

export function LayoutRegister({
  backgroundImage,
  children,
  ...rest
}: LayoutRegisterProps) {
  return (
    <LayoutUnLogged backgroundImage={backgroundImage}>
      <Register {...children} {...rest} />
    </LayoutUnLogged>
  )
}
